import {
  ActionStore,
  createAction,
  createGetter,
  KeyStore,
  on,
  ParamsStore,
  RemoteCollectionStore,
  RemoteEntityStore,
  select,
  SOFTLINE_SERVICE_UUID,
  SOFTLINE_STORE_ENTITY_SERVICE,
  StoreBuilder,
  StoreFeature
} from '@softline/core';
import {Favorite} from '../data/favorite';
import {ShopItem} from '../data/item';

// export type State = LoadCollectionState<Favorite> & CollectionState<Favorite> & SynchronizedCollectionState<Favorite>;
export type State = RemoteCollectionStore.State<Favorite>;

class FavoritesStoreExtension {
  static actions = {
    add: createAction<State, { entity: Favorite }, undefined>('addToFavorites'),
    remove: createAction<State, { entity: Favorite }, undefined>('removeFromFavorites')
  };

  static getters = {
    selectUpdatingFavorites: createGetter<State, ShopItem[], undefined>('selectUpdatingFavorites')
  };

  static feature: Partial<StoreFeature<State>> = {
    actions: [
      on(FavoritesStoreExtension.actions.add, async ({featureName, commit, params, injector}) => {
        const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.create(params.entity);
        const result = await ActionStore.handleObservableActionState(
          observable, featureName, commit, FavoritesStoreExtension.actions.add.name, token
        ).toPromise();
        commit(featureName, RemoteCollectionStore.mutations.add, result);
        return result;
      }),
      on(FavoritesStoreExtension.actions.remove, async ({featureName, commit, params, injector}) => {
        const service = injector.get(SOFTLINE_STORE_ENTITY_SERVICE);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.delete(params.entity);
        const result = await ActionStore.handleObservableActionState(
          observable, featureName, commit, FavoritesStoreExtension.actions.remove.name, token
        ).toPromise();
        commit(featureName, RemoteCollectionStore.mutations.remove, result);
        return result;
      })
    ],
    getters: [
      select(FavoritesStoreExtension.getters.selectUpdatingFavorites, ({state}) => {
        return [...state.keys.map(key => ({key, action: state.actions[key], param: state.params[key]}))]
            .filter(({action}) => action !== undefined && action.action && (action.state === 'pending' || action.state === 'processing'))
            .map(o => (o.param as unknown as { entity: Favorite })?.entity.item)
      })
    ]
  };
}

export const FavoritesStore = new StoreBuilder(RemoteCollectionStore.create<Favorite>())
  .add(FavoritesStoreExtension)
  .value;
