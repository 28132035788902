import { Routes } from "@angular/router";
import { AuthenticationGuard } from "@softline/auth";
import { loginRoutes } from "@softapps/allgemein/login";
import { connectionSettingsRoutes, settingsRoutes } from "@softapps/allgemein/utils";
import { passwordRoutes } from "@softapps/allgemein/password";
import { moduleRoutes } from "@softapps/allgemein/modules";
import { bestellungRoutes } from "@softapps/gewerbe/bestellung";
import { dokumentationRoutes } from "@softapps/gewerbe/dokumentation";
import { arbeitsberichtRoutes } from "@softapps/gewerbe/arbeitsbericht";
import {objektabfrageRoutes} from '@softapps/wws/objektabfrage';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.ShellComponent),
    children: [
      //Allgemein
      ...moduleRoutes,
      ...settingsRoutes,

      //Gewerbe
      ...dokumentationRoutes,
      ...bestellungRoutes,
      ...arbeitsberichtRoutes,
      ...objektabfrageRoutes
    ],
    canActivate: [AuthenticationGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('@softline/application').then((o) => o.PublicShellComponent),
    children: [ ...loginRoutes, ...passwordRoutes, ...connectionSettingsRoutes, ...settingsRoutes],
  },
];
