import { InjectionToken } from '@angular/core';

export const SOFTLINE_CONFIG_ARBEITSBERICHT = new InjectionToken<ArbeitsberichtConfig>(
  'TIME_REPORT_CONFIG'
);

export interface ArbeitsberichtConfig {
  clickAction?: 'select' | 'navigate';
  useSecondaryBorderStyle?: boolean;
  showGeodaten?: boolean;
  hideEntryInDetails?: boolean;
  allowDelete?: boolean;
  editableDaysBeforeToday?: number;
  editableWeeksBeforeToday?: number;
  actions: {
    label: string;
    icon: string;
    actionType: 'scanOrder' | 'recentObjects';
  }[];
}
