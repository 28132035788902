<h1 class="text-xl font-semibold mt-4 mb-2">
  {{ '#OBJEKT.INPUT.OBJEKTAUSWAHL' | translate }}
</h1>

<section class="object-query-input-border rounded mt-4">
  <p class="font-sm text-lighter mb-3">
    {{ '#OBJEKT.INPUT.OBJEKT_AUSWAEHLEN_TEXT' | translate }}
  </p>

  <div class="custom-button-container mt-2 mb-2">
    <span class="custom-link-button rounded" (click)="scanOrder()">
      {{ '#DOCUMENTATION.ACTION.SCAN_ORDER' | translate }}
      <i class="ml-2" [ngClass]="'fa-regular fa-barcode-scan'"></i>
    </span>
    <span class="custom-link-button rounded" (click)="showRecentObjectsDialog()">
      {{ '#DOCUMENTATION.ACTION.RECENT_OBJECTS' | translate }}
      <i class="ml-2" [ngClass]="'fa-regular fa-clock-rotate-left'"></i>
    </span>
  </div>
  <soft-value-converter [converter]="objectConverter" (valueChange)="selectObject($event)">
    <soft-field-ok
      class="mt-3"
      [name]="'objekt'"
      [parameters]="{ status: 'O' }"
      [placeholder]="'#OBJEKT.INPUT.PLACEHOLDER_TEXT' | translate"
      #convertValue
    >
    </soft-field-ok>
  </soft-value-converter>
</section>

