<div class="c column" style="overflow-y: hidden">
  <div
    class="c row space-between no-wrap center-v pt-2 pr-2 pl-2 pb-2 border-box"
  >
    <h4>{{ '#TIME_REPORT.DIALOGS.CONFIRM_DELETE.TITLE' | translate }}</h4>
  </div>

  <p class="c row no-wrap center-v pr-2 pl-2 pb-2 border-box soft-text">
    {{ '#TIME_REPORT.DIALOGS.CONFIRM_DELETE.DESC' | translate }}
  </p>

  <div class="c row end no-wrap center-v pt-2 pr-2 pl-2 pb-2 border-box">
    <button class="soft-button secondary" (click)="close(false)">
      Abbrechen
    </button>
    <button class="soft-button primary ml-2" (click)="close(true)">
      Unwiderruflich löschen
    </button>
  </div>
</div>
