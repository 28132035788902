import {ShopStammdaten} from '../data/stammdaten';
import {createMutation, mutate, ObjectStore, StoreBuilder, StoreFeature, SyncedRemoteObjectStore} from '@softline/core';

export interface State extends ObjectStore.State<ShopStammdaten> {}

class StammdatenStoreExtension {
  static mutations = {
    setVkforg: createMutation<State, { vkforgId: number | null }>('setVkforg')
  };

  static feature: Partial<StoreFeature<State>> = {
    mutations: [
      mutate(StammdatenStoreExtension.mutations.setVkforg, ({state, params}) =>
        ({...state, entity: {...state?.data, selectedAuftragVkfOrg: params.vkforgId}}))
    ]
  };
}

export const StammdatenStore = new StoreBuilder(SyncedRemoteObjectStore.create<ShopStammdaten>())
  .add(StammdatenStoreExtension)
  .value;
