export const SOFTLINE_API_TIME_REPORT = '/v1/timereport/entities';
export const TIME_REPORT_DETAIL_RESOURCE_PATH =
  '/v1/timereport/entities/{{id}}';

export const COMPANY_DATA_RESOURCE_PATH = '/v1/stammdaten';
export const OBJECTS_RESOURCE_PATH = '/v1/objekt/entities';

export const PARTY_FAVORITES_RESOURCE_PATH =
  '/v1/timereport/favorites/entities';

export const SCAN_OBJECT_RESOURCE_PATH = '/v1/objekt/scan/{{type}}/{{id}}';
export const SCAN_GET_OBJECT_RESOURCE_PATH =
  '/v1/bean/abfrageResult/objekt/{{id}}';
export const GET_OBJECT_BY_ID_RESOURCE_PATH = '/v1/objekt/entities/{{id}}';

export const TIME_REPORT_TEMPLATE_RESOURCE_PATH = '/v1/timereport/default';
export const ARCHIVE_KEY_RESOURCE_PATH =
  '/v1/timereport/entities/{{id}}/archivekey';


// DEFINITIONS
export const SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST = 'arbeitsbericht-list-definition';
export const SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT = 'arbeitsbericht-input-definition';
export const SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT = 'arbeitsbericht-edit-definition';
export const SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY = 'arbeitsbericht-query-definition';
