import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Voucher} from '../../../data/voucher';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {OrderDetailComponent} from '../order-detail/order-detail.component';

@Component({
  selector: 'lib-order-history-item',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './order-history-item.component.html',
  styleUrls: ['./order-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistoryItemComponent {
  @Input() order: Voucher | null = null;
}
