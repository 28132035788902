import {
  ConnectionResourceLocation,
  CRUDCollectionParameters, Dictionary,
  HttpApiCollectionRepository,
  Id, LoadCollectionItemParameters, LoadCollectionParameters,
  ResourceService, Store
} from '@softline/core';
import { TimeReport } from '../models/time-report.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT } from '@softline/auth';

export class TimeReportRepository extends HttpApiCollectionRepository<TimeReport, {result: TimeReport}, {result: TimeReport[]}> {
  constructor(private store: Store,
              service: ResourceService<ConnectionResourceLocation>,
              path: string | string[]) {
    super(service, path);
  }

  override loadMany(params?: LoadCollectionParameters): Observable<{ result: TimeReport[], sum: Partial<TimeReport> }> {
    params = {...params, query: this.convertQuery(params?.query as any) as any}
    return super.loadMany(params).pipe(
      map(o => ({result: o.result.map(p => this.convertResponse(p)), sum: o['sum']}))
    );
  }

  override loadItem(id: Id, params: LoadCollectionItemParameters): Observable<{ result: TimeReport }> {
    return super.loadItem(id, params).pipe(
      map(o => ({result: this.convertResponse(o.result)}))
    );
  }

  override create(id: Id | null | undefined, value: TimeReport, params?: CRUDCollectionParameters | undefined): Observable<{result: TimeReport}> {
    value = this.convertRequest(value);
    return super.create(id, value, params)
      .pipe(
        map(result => ({ result: this.convertResponse(result.result) }))
      );
  }

  override update(id: Id, value: TimeReport, params?: CRUDCollectionParameters | undefined): Observable<{result: TimeReport}> {
    value = this.convertRequest(value);
    return super.update(id, value, params)
      .pipe(
        map(result => ({ result: this.convertResponse(result.result) }))
      );
  }

  override delete(id: Id, params?: CRUDCollectionParameters | undefined): Observable<{result: TimeReport}> {
    return super.delete(id, params)
      .pipe(
        map(result => ({ result: this.convertResponse(result.result) }))
      );
  }

  convertResponse(value: TimeReport): TimeReport {
    value = {...value};
    // TODO: Time
    if (value.from && !value.from.startsWith('T')) {
      value.from = 'T' + value.from;
    }
    if (value.to && !value.to.startsWith('T')) {
      value.to = 'T' + value.to;
    }

    if (
      value?.zbewegungsarten &&
      Array.isArray(value?.zbewegungsarten) &&
      value?.zbewegungsarten?.length > 0
    ) {
      value.zbewegungsarten = value.zbewegungsarten[0];
    }

    return value;
  }

  convertRequest(value: TimeReport): TimeReport {
    if (value.from?.startsWith('T'))
      value.from = value.from.substring(1, 9);
    if (value.to?.startsWith('T'))
      value.to = value.to.substring(1, 9);
    if(value.zbewegungsarten && !Array.isArray(value?.zbewegungsarten))
      value.zbewegungsarten = [value?.zbewegungsarten];
    return value as TimeReport;
  }

  convertQuery(value: Dictionary<any>): TimeReport {
    const query: Dictionary<any> = {};
    if (value['from'])
      query['from'] = value['from'].substring(0, 10);
    if (value['to'])
      query['to'] = value['to'].substring(0, 10);
    if(value['zbewegungsarten'] && !Array.isArray(value['zbewegungsarten']))
      query['zbewegungsarten'] = [value['zbewegungsarten']];
    else if(value['zbewegungsarten'])
      query['zbewegungsarten'] = value['zbewegungsarten'];
    if(value['objekt'])
      query['objektid'] = value['objekt'].id;
    query['userId'] = this.store.get(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data)['userId'];
    return query as TimeReport;
  }
}
