<h4 class="text-lg font-semibold mb-3 mt-3 pr-3 pl-3">
  <ng-container *ngIf="mode === 'add'; else editTitleTemplate">
    {{ '#SHOP.DIALOGS.CART.ADD.TITLE' | translate }}
  </ng-container>
  <ng-template #editTitleTemplate>
    {{ '#SHOP.DIALOGS.CART.EDIT.TITLE' | translate }}
  </ng-template>
</h4>

<div class="item-shell pr-3 pl-3 pt-2 pb-2 border-box mr-3 ml-3">
  <div class="soft-overline color-tertiary mt-1">{{ '#SHOP.DIALOGS.CART.ITEM_HEADER' | translate }}</div>
  <div class="c column no-wrap-center-v mt-1 truncate mb-1 border-box">
      <div class="text-xs overline-list overline-light-list mb-1 mr-2">{{ item?.id }}</div>
      <span class="truncate">{{ item?.name }}</span>
  </div>
</div>

<div class="item-shell pr-3 pl-3 pt-2 pb-2 border-box mr-3 ml-3 mt-3 mb-3">
  <div class="soft-overline color-tertiary mt-1">{{ '#SHOP.DIALOGS.CART.QTY_HEADER' | translate }}</div>
  <span class="text-sm color-light mt-1">
    {{ '#SHOP.DIALOGS.CART.ADD.DESCRIPTION' | translate }}
  </span>

  <div class="c row space-between border-box no-wrap center-v">
    <soft-number-input [placeholder]="'#SHOP.DIALOGS.CART.INPUT_PLACEHOLDER'" class="border-box mt-2 mb-1" style="width: 100%" #amount [value]="quantity"></soft-number-input>
    <span class="text-sm" style="width: auto; margin-bottom: -2px; padding: 0.25rem 0.25rem 0.25rem 1rem">
      {{ item?.unit?.title }}
    </span>
  </div>
</div>

<div class="ml-3 mr-3 mb-3 border-box">
  <soft-message-bar-container [name]="'ADD_CART_DIALOG_MESSAGE_BAR_CONTAINER'" class="mb-3">
    <soft-message-bar class="border-box"></soft-message-bar>
  </soft-message-bar-container>
</div>

<div class="c row no-wrap">
  <button class="soft-button secondary large-button w-full" (click)="close(null)">{{ '#SHOP.DIALOGS.CART.CANCEL_BTN' | translate }}</button>
  <button class="soft-button primary large-button w-full" (click)="onSubmit((+(amount?.value ?? 0)) || 1)" [disabled]="(+(amount?.value ?? 0)) < 1">
    <ng-container *ngIf="!(isLoading$ | async); else loadingSpinner">
      <ng-container *ngIf="mode === 'add'">{{ '#SHOP.DIALOGS.CART.ADD.SUBMIT' | translate }}</ng-container>
      <ng-container *ngIf="mode === 'edit'">{{ '#SHOP.DIALOGS.CART.EDIT.SUBMIT' | translate }}</ng-container>
    </ng-container>
    <ng-template #loadingSpinner>
      <soft-loading-spinner class="small spin"></soft-loading-spinner>
    </ng-template>
  </button>
</div>
