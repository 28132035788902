<ng-container *ngIf="order$ | async as order">
  <div class="c column no-wrap">
    <h1 *ngIf="order.items" class="text-xl font-bold mt-4">{{ '#SHOP.ORDER_HISTORY.DETAILS.TITLE' | translate }} {{ order.items[0]?.vkforg?.vkforg }}</h1>
    <span class="text-sm color-light">{{ '#SHOP.ORDER_HISTORY.DETAILS.SUBTITLE' | translate }} {{ order.number }}</span>
  </div>

  <section class="c column no-wrap pb-2 mt-1">
    <header class="soft-overline mt-4 mb-1" style="color: var(--colorTertiary)">{{ '#SHOP.ORDER_HISTORY.DETAILS.INFO_OVERLINE' | translate }}</header>
    <div class="c column rounded bordered mb-2 mt-1">
      <span class="soft-overline" style="color: var(--colorText)">{{ '#SHOP.ORDER_HISTORY.DETAILS.CUSTOMER_LABEL' | translate }}</span>
      <span *ngIf="order.items" class="text-sm color-light">{{ order.items[0]?.objektUrsache?.objekt }} - {{ order.items[0]?.objektUrsache?.bezeichnung }}</span>
    </div>
    <div class="auto-grid mt-1 mb-3" style="width: 100%">
      <div class="c column rounded bordered">
        <span class="soft-overline" style="color: var(--colorText)">{{ '#SHOP.ORDER_HISTORY.DETAILS.DATE_LABEL' | translate }}</span>
        <span class="text-sm color-light">{{ order?.date | date: 'dd.MM.yyyy' }}</span>
      </div>
      <div class="c column rounded bordered">
        <span class="soft-overline" style="color: var(--colorText)">{{ '#SHOP.ORDER_HISTORY.DETAILS.COUNT_LABEL' | translate }}</span>
        <span class="text-sm color-light">{{ order?.items?.length }} Artikel</span>
      </div>
      <div class="c column rounded bordered">
        <span class="soft-overline" style="color: var(--colorText)">{{ '#SHOP.ORDER_HISTORY.DETAILS.STATUS_LABEL' | translate }}</span>
        <span class="text-sm color-light">{{ order?.status }}</span>
      </div>
      <div class="c column rounded bordered">
        <span class="soft-overline" style="color: var(--colorText)">{{ '#SHOP.ORDER_HISTORY.DETAILS.VKFORG_LABEL' | translate }}</span>
        <span *ngIf="order.items" class="text-sm color-light">{{ order.items[0]?.vkforg?.vkforg }}</span>
      </div>
    </div>
  </section>

  <section class="c column no-wrap pb-2" style="width: 100%">
    <header class="soft-overline mt-3 mb-1" style="color: var(--colorTertiary);">{{ '#SHOP.ORDER_HISTORY.DETAILS.ITEMS_OVERLINE' | translate }}</header>
    <lib-item-list [items]="order.items | toShopItems"></lib-item-list>
  </section>

  <div class="fixed-button mt-5">
    <button class="soft-button primary custom-button-size" (click)="navigateBack()">{{ '#SHOP.ORDER_HISTORY.DETAILS.CLOSE_BTN' | translate }}</button>
  </div>
</ng-container>
