import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of} from 'rxjs';
import {Store} from '@softline/core';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {SOFTLINE_FEATURE_SHOP_FAVORITES} from '../shop.shared';
import {FavoritesStore} from '../store/favorites.store';

@Injectable()
export class FavoritesStoreGuard  {

  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private checkStore(): Observable<boolean> {
    return this.store.observe(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.getters.loaded).pipe(
      tap(async loaded => {
        console.log('loaded favorites: ', loaded);
        if (loaded) { return; }
        console.log('loading favorites...');
        await this.store.dispatch(SOFTLINE_FEATURE_SHOP_FAVORITES, FavoritesStore.actions.loadMany, {clear: true});
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
