import { computed, Directive, effect, forwardRef, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MetaBaseDirective } from './meta-base.directive';

@Directive({
  selector: '[softMetaFieldName]',
  standalone: true,
  providers: [
    {provide: MetaBaseDirective, useExisting: forwardRef(() => MetaFieldNameDirective)}
  ]
})
export class MetaFieldNameDirective<T> extends MetaBaseDirective<T> {
  private template = inject(TemplateRef);
  private vcRef = inject(ViewContainerRef);
  metaDirective = inject(MetaBaseDirective, {skipSelf: true});

  name = input<string | null>(null, {alias: 'softMetaFieldName'});

  field = computed(() => {
    const metaDirective = this.metaDirective;
    const name = this.name();
    if(!metaDirective)
      throw new Error('[MetaFieldDirective] MetaDirective is not provided.');
    const meta = metaDirective.metaObject();
    if(!meta)
      throw new Error('[MetaFieldDirective] MetaObject is not provided.');
    if(!name)
      return null;
    return meta[name];
  });

  format = computed(() => {
    const field = this.field();
    if(!field)
      return undefined;
    return field['format'];
  });

  fieldEffect = effect(() => {
    const field = this.field();
    if(!field)
      return;
    if(field?.visible === false || !field)
      this.vcRef.clear();
    else if(this.vcRef.length === 0)
      this.vcRef.createEmbeddedView(this.template);
  });

  metaObject = computed(() => {
    let meta = this.metaDirective.metaObject();
    const field = this.field();
    if(field?.type === 'object' && field?.object)
      meta = field.object;
    return meta;
  })

  constructor() {
    super();
  }
}
