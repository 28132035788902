import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelected',
  pure: true,
  standalone: true,
})
export class IsSelectedPipe<T> implements PipeTransform {
  transform(
    value: T,
    items: T[],
    comparer: (item: T, items: T[]) => boolean
  ): boolean {
    return comparer(value, items) ?? false;
  }
}
