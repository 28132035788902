export const ITEM_ATTRIBUTES = '/v1/items/attributes/entities';
export const ITEM_ENTITIES = '/v1/items/entities';
export const FAVORITES_RESOURCE_PATH = '/v1/items/favorites/entities';
export const CART_RESOURCE_PATH = '/v1/checkout';
export const CART_ITEMS_RESOURCE_PATH = '/v1/checkout/items/entities';
export const RECENT_OBJECTS_RESOURCE_PATH = '/v1/objekt/entities';
export const ORDER_HISTORY_RESOURCE_PATH = '/v1/shop/vouchers/entities';
export const SCAN_OBJECT_RESOURCE_PATH = '/v1/objekt/scan/{{type}}/{{id}}';
export const SCAN_GET_OBJECT_RESOURCE_PATH = '/v1/bean/abfrageResult/objekt/{{id}}';
export const OBJECT_DETAIL_RESOURCE_PATH = '/v1/objekt/entities/{{id}}';
export const GET_STAMMDATEN = '/v1/stammdaten';
