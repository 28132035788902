import {createAction, on, RemoteCollectionStore, RemoteEntityStore, StoreBuilder, StoreFeature,} from '@softline/core';
import {Objekt} from './data/objekt';
import {ArchiveKeyService} from './services/archive-key.service';
import {lastValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {ScanOrderService} from './services/scan-order.service';

export const SOFTLINE_FEATURE_OBJECT_QUERY = 'SOFTLINE_FEATURE_OBJECT_QUERY';
export const SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS = 'SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS';

export const SOFTLINE_PERMISSION_OBJECT_QUERY = 'OBJEKTABFRAGE';

class ArchiveKeyExtension {
  static actions = {
    loadArchiveKey: createAction<unknown, Objekt, void>('LoadArchiveKey'),
  };

  static feature: StoreFeature<any> = {
    initialState: { ...RemoteEntityStore.feature.initialState },
    getters: [],
    mutations: [],
    actions: [
      on(
        ArchiveKeyExtension.actions.loadArchiveKey,
        async ({ featureName, commit, injector, params }) => {
          const service = injector.get(ArchiveKeyService);
          const archiveKey = await lastValueFrom(service.loadArchiveKey(params.id));
          if (archiveKey && archiveKey?.length > 0) {
            commit(featureName, RemoteEntityStore.mutations.set, {
              ...params,
              archiveKey,
            } as any);
          }
        }
      ),
    ],
  };
}

class RecentObjectsStoreExtension {
  static actions = {
    loadObject: createAction<RemoteCollectionStore.State, { order: string }, Objekt>('loadObject'),
  };

  static feature = {
    actions: [
      on(RecentObjectsStoreExtension.actions.loadObject, async ({ params, injector }) => {
        const service = injector.get(ScanOrderService);
        console.log('load object...')
        return await lastValueFrom(
          service.getObjectForOrder(params.order).pipe(
            take(1)
          )
        )
      }),
    ],
  };
}

export const ObjectQueryStore = new StoreBuilder(RemoteEntityStore.create<Objekt>())
  .add(ArchiveKeyExtension)
  .value;

export const ObjectQueryRecentObjectsStore = new StoreBuilder(RemoteCollectionStore.create<Objekt>())
  .add(RecentObjectsStoreExtension)
  .value;
