import { SOFTLINE_PERMISSION_DOCUMENTATION } from './documentation.shared';
import {
  ApplicationStore,
  SOFTLINE_FEATURE_APPLICATIONS,
} from '@softline/application';
import {
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { Store } from '@softline/core';
import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { default as de } from '../i18n/de.json';

export const dokumentationProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'dokumentation',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#DOCUMENTATION.APP',
          icon: 'fa-regular fa-notebook',
          route: '/dokumentation',
          permission: SOFTLINE_PERMISSION_DOCUMENTATION,
        }
      );
    },
    multi: true,
  },
];
