import {
  ActionStore,
  CollectionStore,
  createAction,
  on,
  SOFTLINE_SERVICE_UUID,
  StoreBuilder,
  StoreFeature,
  SubscriptionStore,
  SyncedRemoteCollectionStore,
} from '@softline/core';
import { FavoriteEmployee } from '../models/favorite-employee';
import { Employee } from '../models/employee.model';
import { PartyFavoritesService } from '../services/party-favorites.service';

export class PartyFavoritesStoreExtension {
  static actions = {
    markAsFavorite: createAction<
      SyncedRemoteCollectionStore.State<FavoriteEmployee>,
      { employee: Employee },
      FavoriteEmployee
    >('markAsFavorite'),
  };

  static feature: Partial<
    StoreFeature<SyncedRemoteCollectionStore.State<FavoriteEmployee>>
  > = {
    actions: [
      on(
        PartyFavoritesStoreExtension.actions.markAsFavorite,
        async ({ injector, commit, params, featureName, state }) => {
          const service = injector.get(PartyFavoritesService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();

          const observable$ = await service.markAsFavorite({
            id: params.employee.id,
            lstfixBean: { ...params.employee },
          });
          const subscription$ = SubscriptionStore.handleSubscriptionState(
            observable$,
            featureName,
            commit,
            token
          );
          const result = await ActionStore.handleObservableActionState(
            subscription$,
            featureName,
            commit,
            PartyFavoritesStoreExtension.actions.markAsFavorite.name,
            token
          ).toPromise();

          commit(featureName, CollectionStore.mutations.add, result);
          return result;
        }
      ),
    ],
  };
}

export const PartyFavoritesStore = new StoreBuilder(
  SyncedRemoteCollectionStore.create<FavoriteEmployee>()
).add(PartyFavoritesStoreExtension).value;
