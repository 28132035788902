<form [formGroup]="form" [softMetaForm]="metaForm()">
  <soft-query-page (query)="query($event)"
                   [shortcuts]="shortcuts()">
    <soft-page-header>
      <h1 class="text-xl">{{'#TIME_REPORT.COMPONENTS.QUERY.SUBTITLE' | translate}}</h1>
    </soft-page-header>

    <div class="soft-grid">
      <soft-meta-form-cell name="from" class="col-span-6 sm:col-span-3">
        <soft-date-range-from-input formControlName="from" [toControl]="to" softFieldValidation #from></soft-date-range-from-input>
      </soft-meta-form-cell>
      <soft-meta-form-cell name="to" class="col-span-6 sm:col-span-3">
        <soft-date-range-to-input formControlName="to" [fromControl]="from" softFieldValidation #to></soft-date-range-to-input>
      </soft-meta-form-cell>
      <soft-meta-form-cell name="objekt" class="col-span-6">
        <soft-objekt-field-ok formControlName="objekt" softFieldValidation></soft-objekt-field-ok>
      </soft-meta-form-cell>
    </div>
  </soft-query-page>
</form>
