import { MetaForm, MetaObject } from '@softline/dynamic';
import { fieldOkObjectMeta } from '@softapps/wws/core';
import { TimeReportQuery } from '../time-report.query';

export const timeReportQueryMeta: MetaForm<TimeReportQuery> = {
  from: {
    type: 'date',
    title: 'Von',
    required: true,
    format: 'DATE.DEFAULT',
    icon: 'fa-regular fa-calendar-day'
  },
  to: {
    type: 'date',
    title: 'Bis',
    required: true,
    format: 'DATE.DEFAULT',
    icon: 'fa-regular fa-calendar-day'
  },
  objekt: {
    type: 'fieldOk',
    title: 'Objekt',
    fieldOk: 'objekt',
    icon: 'fa-regular fa-frame',
    format: '{{objekt}} - {{bezeichnung}}',
    object: fieldOkObjectMeta,
  }
}
