import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShopItem } from '../../data/item';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import { RequestError, Store } from '@softline/core';
import { CartItem } from '../../data/cart-item';
import { BehaviorSubject } from 'rxjs';
import { SOFTLINE_FEATURE_SHOP_CART } from '../../shop.shared';
import { CartStore } from '../../store/cart.store';
import { handleRequestErrors } from '@softline/application';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-cart-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartDialogComponent implements Modal<any> {

  mode: 'add' | 'edit' = 'add';
  quantity = 1;
  item: ShopItem | null = null;
  close: any = undefined;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store) { }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  private async add(amount: number): Promise<void> {
    this.isLoading$.next(true);
    const entity: Partial<CartItem> = { id: 0, item: this.item, amount };

    try {
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.add, { entity });
      this.isLoading$.next(false);
      this.close(null);
      await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.success, 'Der Artikel wurde erfolgreich zum Warenkorb hinzugefügt');
    } catch (e) {
      this.isLoading$.next(false);
      if (e instanceof RequestError) {
        await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
          title: '#SHOP.SHARED.ERRORS.TITLE',
          message: (e as any)?.errors[0]?.message,
          duration: 5000,
          container: 'ADD_CART_DIALOG_MESSAGE_BAR_CONTAINER'
        });
      } else {
        handleRequestErrors(this.store, e);
      }
    }
  }

  private async update(amount: number): Promise<void> {

    const cart = this.store.get(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.data);

    console.log('items: ', cart.items)
    console.log('item: ', this.item)
    const cartItem = cart.items.find(o => o?.item?.id && o.item?.id === this.item?.id);
    if (!cartItem) { return; }

    this.isLoading$.next(true);
    const updatedCartItem: CartItem = { ...cartItem, amount };

    try {
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.update, updatedCartItem);
      this.isLoading$.next(false);
      this.close(null);
    } catch (e) {
      this.isLoading$.next(false);
      if (e instanceof RequestError) {
        await this.store.dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.error, {
          title: '#SHOP.SHARED.ERRORS.TITLE',
          message: (e as any)?.errors[0]?.message,
          duration: 5000,
          container: 'ADD_CART_DIALOG_MESSAGE_BAR_CONTAINER'
        });
      } else {
        handleRequestErrors(this.store, e);
      }
    }
  }

  async onSubmit(amount: number): Promise<void> {
    (this.mode === 'add')
      ? await this.add(amount)
      : await this.update(amount);
  }
}
