import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule,
} from '@softline/core';
import {
  SOFTLINE_FEATURE_DOCUMENTATION,
  SOFTLINE_PERMISSION_DOCUMENTATION,
} from './documentation.shared';
import { DocumentationStore } from './store/documentation.store';
import { RECENT_OBJECTS_RESOURCE_PATH } from './documentation.api';
import { ScanOrderService } from './services/scan-order.service';

export const dokumentationRoutes: Routes = [
  {
    path: 'dokumentation',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/documentation.component').then(
            (o) => o.DocumentationComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./components/create/create.component').then(
                (o) => o.CreateComponent
              ),
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_DOCUMENTATION },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_DOCUMENTATION,
          feature: DocumentationStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(
                  service,
                  RECENT_OBJECTS_RESOURCE_PATH
                ),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: ScanOrderService,
              useFactory: (service: ConnectionHttpService) => {
                const orderService = new ScanOrderService(service);
                return orderService;
              },
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        })
      ),
    ],
  },
];
