import {ShopItem} from '../data/item';
import {
  ActionStore,
  createAction,
  KeyStore,
  on, ParamsStore, RemoteCollectionStore,
  RemoteObjectStore,
  SOFTLINE_SERVICE_UUID,
  StoreBuilder,
  StoreFeature,
  SyncedRemoteCollectionStore
} from '@softline/core';
import {ItemResourceService} from '../services/item-resource.service';
import {lastValueFrom} from 'rxjs';

export type State = RemoteCollectionStore.State<ShopItem>;

class ItemsStoreExtension {
  static actions = {
    search: createAction<State, { query: string, finished?: () => void }, ShopItem[]>('search')
  };

  static feature: Partial<StoreFeature<State>> = {
    actions: [
      on(ItemsStoreExtension.actions.search, async ({featureName, commit, params, injector}) => {
        const service = injector.get(ItemResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        commit(featureName, KeyStore.mutations.add, token);
        commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.search(params.query);
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            observable, featureName, commit, ItemsStoreExtension.actions.search.name, token
          )
        );
        commit(featureName, RemoteCollectionStore.mutations.addOrUpdateMany, result);
        if (params.finished !== undefined) { params.finished(); }
        return result;
      })
    ]
  };
}

export const ItemsStore = new StoreBuilder(SyncedRemoteCollectionStore.create<ShopItem>())
  .add(ItemsStoreExtension)
  .value;
