import { Injectable } from '@angular/core';
import { base64Encode, Store } from '@softline/core';
import { ShopItem } from '../data/item';
import { CartItem } from '../data/cart-item';
import { CartDialogComponent } from '../dialogs/cart-dialog/cart-dialog.component';
import { Cart } from '../data/cart';
import { Objekt } from '../data/objekt';
import { SOFTLINE_FEATURE_SHOP_CART } from '../shop.shared';
import { CartStore } from '../store/cart.store';
import { PriceInformation } from '../data/price';
import { ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';
import {EditCartItemDialogComponent} from '../dialogs/edit-cart-item-dialog/edit-cart-item-dialog.component';
import {PatchContextStore, SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT} from '@softapps/allgemein/utils';

@Injectable({ providedIn: 'root' })
export class CartService {

  readonly items$ = this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.cartItems);
  readonly loading$ = this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.loading);
  readonly selectedObject$ = this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.selectedObject);
  readonly processing$ = this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.processing);
  readonly getOrderLocation$ = this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.orderLocation);

  constructor(private store: Store) {}

  async load(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.get);
  }

  async showAddToCart(item: ShopItem, amount: number | null = null): Promise<void> {
    await this.showAddToCartDialog(item, amount);
  }

  async remove(item: CartItem): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.remove, { id: item.id });
  }

  async order(): Promise<void> {
    const cart = this.getCart();
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.order, { ...cart});
  }

  async setAddress(cart: Cart): Promise<void>  {
    const encodedCard = base64Encode(JSON.stringify(cart));
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.setAddress, { encodedCard });
  }

  selectObject(object: Objekt): void {
    this.store.commit(SOFTLINE_FEATURE_SHOP_CART, CartStore.mutations.selectObject, { object });
  }

  async changeQuantity(quantity: number, cartItem: CartItem): Promise<void> {
    const item: CartItem = { ...cartItem, amount: quantity };
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.update, { id: cartItem.id, item });
  }

  getCart(): Cart {
    const cartState = this.store.get(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.cartState);
    return {
      id: cartState?.id,
      items: Object.values(cartState?.items),
      deliveryAddress: cartState?.deliveryAddress,
      total: cartState?.total as PriceInformation,
      object: cartState?.object as Objekt,
    } as Cart;
  }

  private async showAddToCartDialog(item: ShopItem, quantity: number | null): Promise<void> {
    if (quantity !== null) {
      const cartItem = this.getCart().items.find(o => o.item?.id === item.id)

      if (!cartItem)
        return;

      await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
        id: 'SHOP_CART_EDIT',
        component: EditCartItemDialogComponent,
        data: { item: cartItem, quantity },
        dismiss: { button: true, backdrop: false, escape: true }
      });
    } else {
      await this.store.dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.open(), {
        id: 'ADD_TO_CART',
        component: CartDialogComponent,
        data: { item, quantity, mode: quantity !== null ? 'edit' : 'add' },
        dismiss: { escape: true, backdrop: false, button: true }
      });
    }
  }
}
