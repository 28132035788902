import {Voucher} from '../data/voucher';
import {
  createAction,
  on,
  RemoteCollectionStore,
  StoreBuilder,
  StoreFeature,
  SyncedRemoteCollectionStore
} from '@softline/core';

export type State = RemoteCollectionStore.State<Voucher>; // & { selected: number | null }

class OrderHistoryStoreExtension {
  static actions = {
    selectOrder: createAction<State, { id: number | null }, any>('selectOrder')
  };

  static feature: Partial<StoreFeature<State>> = {
    actions: [
      on(OrderHistoryStoreExtension.actions.selectOrder, ({state, params}) => ({ ...state, selected: params.id }))
    ]
  };
}

export const OrderHistoryStore = new StoreBuilder(SyncedRemoteCollectionStore.create<Voucher>())
  .add(OrderHistoryStoreExtension)
  .value;
