import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@softline/core';
import {Observable} from 'rxjs';
import {Voucher} from '../../data/voucher';
import {BackNavigable, BackNavigationService, SOFTLINE_FEATURE_TITLE, TitleStore} from '@softline/application';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {SOFTLINE_FEATURE_SHOP_ORDER_HISTORY} from '../../shop.shared';
import {OrderHistoryStore} from '../../store/order-history.store';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';
import {OrderDetailComponent} from './order-detail/order-detail.component';
import {OrderHistoryItemComponent} from './order-history-item/order-history-item.component';

@Component({
  selector: 'lib-order-history',
  standalone: true,
  imports: [CommonModule, UiCoreModule, OrderDetailComponent, OrderHistoryItemComponent],
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistoryComponent implements OnInit, OnDestroy, BackNavigable {

  readonly orders$: Observable<Voucher[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.getters.all).pipe(
    map(orders => (orders as Voucher[]).sort((a: Voucher, b: Voucher) => new Date(b.date).getTime() - new Date(a.date).getTime()))
  );

  readonly loading$ = this.store.observe(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.getters.loading);

  constructor(
    private store: Store,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private backNavigationService: BackNavigationService
  ) { }

  readonly trackByFn = (_: number, item: Voucher) => item.id ?? item;

  async ngOnInit(): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#SHOP.TITLE');
    this.backNavigationService.set(this);

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substring(0, 10);
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.actions.loadMany, {
      clear: true,
      queryParams: { allVouchers: true, dateFrom: firstDay, dateTo: lastDay }
    });
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
  }

  async openDetails(order: Voucher): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.actions.selectOrder, { id: order.id });
    await this.router.navigate([order.id], { relativeTo: this.activatedRoute });
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/shop']);
  }
}
