import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Store} from '@softline/core';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {SOFTLINE_FEATURE_SHOP_ORDER_HISTORY} from '../shop.shared';
import {OrderHistoryStore} from '../store/order-history.store';

@Injectable()
export class OrdersStoreGuard  {

  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private checkStore(): Observable<boolean> {
    return this.store.observe(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.getters.loaded).pipe(
      tap(async loaded => {
        console.log('loaded orders ', loaded);
        if (loaded) { return; }
        console.log('loading orders...');
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substring(0, 10);
        await this.store.dispatch(SOFTLINE_FEATURE_SHOP_ORDER_HISTORY, OrderHistoryStore.actions.loadMany, {
          clear: true,
          queryParams: { allVouchers: true, dateFrom: firstDay, dateTo: lastDay }
        });
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
