import {LazyRemoteCollectionStore, RemoteCollectionStore, StoreBuilder} from '@softline/core';
import {Address} from './data/address';
import {ShopItemAttribute} from './data/item-attribute';
import {Objekt} from './data/objekt';

export const SOFTLINE_FEATURE_SHOP_ADDRESS = 'shopCommon';
export const SOFTLINE_FEATURE_SHOP_ATTRIBUTE = 'shopAttribute';
export const SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS = 'shopRecentObjects';
export const SOFTLINE_FEATURE_SHOP_STAMMDATEN = 'shopStammdaten';
export const SOFTLINE_FEATURE_SHOP_ORDER_HISTORY = 'shopOrderHistory';
export const SOFTLINE_FEATURE_SHOP_FAVORITES = 'shopFavorites';
export const SOFTLINE_FEATURE_SHOP_ITEMS = 'shopItems';
export const SOFTLINE_FEATURE_SHOP_CART = 'shopCart';
export const SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION = 'shopAttributeNavigation';

export const SOFTLINE_PERMISSION_SHOP = 'SHOP';

export const AddressStore = LazyRemoteCollectionStore.create<Address>();
export const AttributeStore = new StoreBuilder(RemoteCollectionStore.create<ShopItemAttribute>()).value;
export const RecentObjectsStore = new StoreBuilder(RemoteCollectionStore.create<Objekt>()).value;
