import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { Observable } from 'rxjs';
import { Modal, UiCoreModule } from '@softline/ui-core';
import { CommonModule } from '@angular/common';
import {Objekt} from '../../data/objekt';
import {
  ObjectQueryRecentObjectsStore,
  SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS
} from '../../objektabfrage.shared';

const RECENT_OBJECT_COUNT = 8;

@Component({
  selector: 'lib-recent-objects-dialog',
  standalone: true,
  templateUrl: './recent-objects-dialog.component.html',
  styleUrls: ['./recent-objects-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule],
})
export class RecentObjectsDialogComponent implements OnInit, Modal<Objekt | undefined> {
  close!: (result?: Objekt) => void;

  recentObjects$: Observable<readonly Objekt[]> = this.store.observe(
    SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
    ObjectQueryRecentObjectsStore.getters.all
  );

  loading$: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
    ObjectQueryRecentObjectsStore.getters.loading
  );

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    const queryParams = { letzenArbeitsberichte: RECENT_OBJECT_COUNT };
    await this.store.dispatch(
      SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
      ObjectQueryRecentObjectsStore.actions.loadMany,
      { clear: true, queryParams }
    );
  }

  registerCloseHandler(handler: (result?: Objekt) => void) {
    this.close = handler;
  }
}
