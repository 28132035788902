import {ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, importProvidersFrom, inject, Provider} from '@angular/core';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP, Store, StoreModule} from '@softline/core';
import {SOFTLINE_FEATURE_TRANSLATION, TranslationStore} from '@softline/ui-core';
import {ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS} from '@softline/application';
import {default as de} from '../i18n/de.json';
import {SOFTLINE_FEATURE_SHOP_CART, SOFTLINE_PERMISSION_SHOP} from './shop.shared';
import {SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT, VkforgContextEditorComponent} from '@softapps/allgemein/utils';
import {
  BestellungContextEditorComponent
} from './components/bestellung-context-editor/bestellung-context-editor.component';
import {CartStore} from './store/cart.store';
import {CartResourceService} from './services/cart-resource.service';
import {ScanOrderService} from './services/order-scan.service';

export const bestellungProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'shop',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#SHOP.TITLE',
        icon: 'fa-regular fa-cart-shopping',
        route: '/shop',
        permission: SOFTLINE_PERMISSION_SHOP
      });
    },
    multi: true
  },
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SHOP_CART,
      feature: CartStore.feature,
      providers: [
        {
          provide: CartResourceService,
          useFactory: (service: ConnectionHttpService) => new CartResourceService(service),
          deps: [SOFTLINE_SERVICE_HTTP]
        },
        {
          provide: ScanOrderService,
          useFactory: (service: ConnectionHttpService) => new ScanOrderService(service),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    }),
  ),
  {
    provide: SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
    useValue: BestellungContextEditorComponent,
    multi: true,
  },
];
