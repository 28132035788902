import {LabelType} from '@softline/application';
import {Observable} from 'rxjs';
import {Objekt} from '../data/objekt';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP,} from '@softline/core';
import {switchMap} from 'rxjs/operators';
import {OBJECT_DETAIL_RESOURCE_PATH, SCAN_OBJECT_RESOURCE_PATH} from '../shop.api';
import {Inject, Injectable} from '@angular/core';

@Injectable()
export class ScanOrderService {
  constructor( @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService ) { }

  private getObjectId(orderId: string): Observable<number> {
    return this.service.get<number>({ path: SCAN_OBJECT_RESOURCE_PATH, pathParams: { type: 'code39', id: orderId } });
  }

  getObjectForOrder(orderId: string, type: LabelType | string): Observable<Objekt> {
    return this.getObjectId(orderId).pipe(
      switchMap(objectId =>
        this.service.get<Objekt>({path: OBJECT_DETAIL_RESOURCE_PATH, pathParams: {objectId}})
      )
    );
  }
}
