<section>
  <h6 class="font-semibold text-base mb-1 mt-4">Abhollager ändern</h6>
  <form class="c column no-wrap mb-2" [formGroup]="form">
    <soft-value-converter formControlName="vkforgidinternerauftrag" [converter]="vkforgConverter">
      <soft-field-ok
        #vkfOrgFok
        #convertValue
        class=""

        [value]="vkforg$ | async"
        (valueChange)="selectVkforg($event)"
        [name]="'vkforg'"
        [parameters]="{ firmenBerechtigung: false, 'alle': false }"
        [placeholder]="'Vkf-Org'">
      </soft-field-ok>
    </soft-value-converter>
  </form>
</section>
