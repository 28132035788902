import {
  createAction,
  on,
  RemoteCollectionStore,
  StoreBuilder,
  StoreFeature,
} from '@softline/core';
import { Objekt } from '../data/objekt.model';
import { take } from 'rxjs/operators';
import { ScanOrderService } from '../services/scan-order.service';

export type State = RemoteCollectionStore.State;

class StoreExtension {
  static actions = {
    loadObject: createAction<State, { order: string }, Objekt>('loadObject'),
  };

  static feature = {
    actions: [
      on(StoreExtension.actions.loadObject, async ({ params, injector }) => {
        const service = injector.get(ScanOrderService);
        return await service
          .getObjectForOrder(params.order, 'code39')
          .pipe(take(1))
          .toPromise();
      }),
    ],
  };
}

export const DocumentationStore = new StoreBuilder(
  RemoteCollectionStore.create<Objekt>()
).add(StoreExtension).value;
