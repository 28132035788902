import {Injectable} from '@angular/core';
import {Store} from '@softline/core';
import {ShopItemAttribute} from '../data/item-attribute';
import {
  AttributeStore,
  SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
  SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION
} from '../shop.shared';
import {AttributeNavigationStore} from '../store/attribute-navigation.store';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AttributeNavigationService {

  readonly activeAttributes$: Observable<ShopItemAttribute[]> = this.store.observe(
    SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION,
    AttributeNavigationStore.getters.data
  ).pipe(map(o => o?.attributes ?? []));

  readonly activeAttribute$ = this.activeAttributes$.pipe(
    map((attributes) => {
      return attributes?.length > 0 ? attributes[attributes.length - 1] : undefined;
    }),
  );

  get currentAttributes(): ShopItemAttribute[] {
    return this.store.get(
      SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION,
      AttributeNavigationStore.getters.data
    ).attributes;
  }

  constructor(private store: Store, private router: Router) {
    this.store.commit(SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION, AttributeNavigationStore.mutations.set, {
      attributes: []
    });
  }

  async selectAttribute(attribute: ShopItemAttribute | null | undefined): Promise<void> {

    console.log('SELECT ATTRIBUTE: ', attribute);

    if (!attribute) {
      // Clear attributes (pop to root)
      this.store.commit(SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION, AttributeNavigationStore.mutations.set, {
        attributes: []
      });
      return;
    } else if (this.currentAttributes.findIndex(o => o.id === attribute.id) > -1) {
      const index = this.currentAttributes.findIndex(o => o.id === attribute.id);
      console.log('INDEX: ', index);
      // Pop back to the attribute
      this.store.commit(SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION, AttributeNavigationStore.mutations.set, {
        attributes: [...this.currentAttributes.slice(0, index + 1)]
      });
      return;
    } else {
      // append/push a new attribute to the stack
      this.store.commit(SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION, AttributeNavigationStore.mutations.set, {
        attributes: [...this.currentAttributes, attribute]
      });

      const allAttributes: ShopItemAttribute[] = this.store.get(SOFTLINE_FEATURE_SHOP_ATTRIBUTE, AttributeStore.getters.all) as any;
      const hasChildren = allAttributes.filter(o => o.parentId === attribute?.id)?.length > 0;

      if (!hasChildren) {
        const attributes = this.createAttributeString();
        await this.router.navigate(['/shop/items'], {queryParams: (attributes?.length > 0) ? {attributes} : {}});
      }
    }
  }

  private createAttributeString(): string {
    const { attributes } = this.store.get(SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION, AttributeNavigationStore.getters.data);
    return (attributes?.length > 0)
      ? attributes.reduce((prev, next) => `${prev === '' ? prev : (prev + '-')}${next.id}`, '')
      : '';
  }
}
