import { Unpacked } from '@softline/core';

export type MetaObject<T> = {
  [Property in keyof T]: MetaField<T[Property]>;
}

export type MetaField<T> = StringField | NumberField | BooleanField | DateField | DurationField | TimeField | SelectField | ArrayField<Unpacked<T>> | ObjectField<T> | FieldOkField<T> | FileField | ImageField;

export interface IMetaField {
  type: string;
  visible?: boolean;
  priority?: number;
}

export interface StringField extends IMetaField {
  type: 'string';
}

export interface NumberField extends IMetaField {
  type: 'number';
  format?: string;
}

export interface BooleanField extends IMetaField {
  type: 'boolean';
}

export interface DateField extends IMetaField {
  type: 'date';
  format?: string;
}

export interface TimeField extends IMetaField {
  type: 'time';
  format?: string;
}

export interface DurationField extends IMetaField {
  type: 'duration';
  format?: string;
}

export interface FieldOption {
  value: unknown;
  title: string;
  icon: string;
}

export interface SelectField extends IMetaField {
  type: 'select';
  options: FieldOption[];
}

export interface ArrayField<T> extends IMetaField {
  type: 'array';
  of: T;
}

export interface ObjectField<T> extends IMetaField {
  type: 'object';
  format: string;
  object: MetaObject<T>;
}

export interface FieldOkField<T> extends IMetaField {
  type: 'fieldOk';
  format: string;
  of: MetaObject<T>;
}

export interface FileField extends IMetaField {
  type: 'file';
  accept: string;
}

export interface ImageField extends IMetaField {
  type: 'image';
  accept: string;
}
