<soft-page>
  <soft-page-header>
    <soft-date-navigator
      class="w-full"
      [date]="date()"
      (dateChange)="onDateChange($event)"
      #navigator
    >
    </soft-date-navigator>
  </soft-page-header>
  <soft-time-report-list
    class="mt-4"
    [date]="date()"
    (selectedChange)="openDetails($event)"
  >
  </soft-time-report-list>
</soft-page>
