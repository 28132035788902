import { Component, OnDestroy, OnInit } from '@angular/core';
import { SOFTLINE_FEATURE_TITLE, TitleStore } from '@softline/application';
import { RouterModule } from '@angular/router';
import { Store } from '@softline/core';

@Component({
  selector: 'lib-object-query',
  standalone: true,
  templateUrl: './object-query.component.html',
  styleUrls: ['./object-query.component.scss'],
  imports: [RouterModule],
})
export class ObjectQueryComponent implements OnInit, OnDestroy {
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      'Objektabfrage'
    );
  }

  ngOnDestroy() {
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      ''
    );
    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setSubTitle,
      ''
    );
  }
}
