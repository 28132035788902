import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  ArchiveStore,
  ArchiveUpload,
  BackNavigable,
  BackNavigationService, BlockingLoadingSpinnerComponent,
  CommandStore, handleRequestErrors,
  LabelType,
  ScannerStore,
  SOFTLINE_FEATURE_ARCHIVE, SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_SCANNER
} from "@softline/application";
import { Objekt } from '../../data/objekt.model';
import { BehaviorSubject, firstValueFrom, Subject } from "rxjs";
import {
  FileInputComponent,
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule,
} from '@softline/ui-core';
import { isDefined, RequestError, Store } from "@softline/core";
import { Router } from '@angular/router';
import { Dokumentation } from '../../data/dokumentation.model';
import { RecentObjectsDialogComponent } from '../../dialogs/recent-objects-dialog/recent-objects-dialog.component';
import { DynamicModule, EntityView, FieldOkResultConverter } from "@softline/dynamic";
import { ObjectNotFoundError } from '../../errors/object-not-found.error';
import { HttpErrorResponse } from '@angular/common/http';
import { SOFTLINE_FEATURE_DOCUMENTATION } from '../../documentation.shared';
import { DocumentationStore } from '../../store/documentation.store';
import { CommonModule } from '@angular/common';
import { combineLatestWith, map } from "rxjs/operators";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-create',
  standalone: true,
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, DynamicModule, ReactiveFormsModule, BlockingLoadingSpinnerComponent]
})
export class CreateComponent implements OnInit, OnDestroy, BackNavigable {

  objectValueConverter = new FieldOkResultConverter({ objekt: 'number', bezeichnung: 'name' });

  form = new UntypedFormGroup({
    object: new UntypedFormControl(undefined, Validators.required),
    title: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(''),
    files: new UntypedFormControl(undefined, Validators.required),
  });

  readonly selectedObject$ = new Subject<Objekt | null>();
  readonly isSaving$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    protected router: Router,
    private cdRef: ChangeDetectorRef,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: CreateComponent,
      commands: [
        {
          name: '#DOCUMENTATION.CREATE.SUBMIT',
          class: 'menu bottom-menu accent',
          canExecute: this.isSaving$.pipe(
            combineLatestWith(this.form.statusChanges),
            map(([saving, status]) => !saving &&  status === 'VALID')
          ),
          execute: async () => {
            const doku = this.form.value;
            if (!doku)
              return;
            await this.onSubmit(doku);
          }
        }]
    })
  }

  ngOnDestroy(): void {
    this.backNavigationService.set(undefined);
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, CreateComponent);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/']);
  }

  async onSubmit(entity: Dokumentation) {
    if (this.form.invalid && !this.form.value.object) {
      const title = 'Eingaben überprüfen!';
      const message = 'Es muss ein Objekt ausgewählt werden!';
      const params = { message, title };
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        params
      );
      return;
    }

    if (
      this.form.invalid &&
      (!this.form.value?.files || !(this.form.value?.files?.length > 0))
    ) {
      const title = 'Eingaben überprüfen!';
      const message =
        'Es muss zumindest ein Bild oder Dokument ausgewählt werden!';
      const params = { message, title };
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        params
      );
      return;
    }

    try {
      entity = { ...entity };
      const path = `/v1/objekt/entities/${this.form?.value?.object?.id}/archivekey`;
      const files = this.form.value?.files ?? [new File([], '')];
      const titel = this.form.get('title')?.value ?? '';
      const beschreibung = this.form.get('description')?.value ?? '';
      const archiveKey = entity?.archiveKey ?? { path };

      const uploads: ArchiveUpload[] = [];

      for (const file of files) {
        uploads.push({
          archiveKey,
          files: [file],
          fields: { titel, beschreibung },
        });
      }

      this.isSaving$.next(true);

      const result = await Promise.all(
        uploads.map((upload) =>
          this.store.dispatch(
            SOFTLINE_FEATURE_ARCHIVE,
            ArchiveStore.actions.upload,
            upload
          )
        )
      );

      if (!result) {
        return;
      }
      await this.showSuccess();
    } catch (e) {
      handleRequestErrors(this.store, e);
      this.isSaving$.next(false);
    }
  }

  async showRecentObjectsDialog(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'RECENT_OBJECTS_DIALOG',
        component: RecentObjectsDialogComponent,
        dismiss: { button: true, escape: true, backdrop: false },
      }
    );

    if (!result || result === 'DISMISSED') {
      return;
    }

    this.form.patchValue({ object: result });
    this.selectedObject$.next(result as Objekt);
    this.cdRef.markForCheck();
  }

  async scanOrder(): Promise<void> {
    try {
      const scanAction = ScannerStore.actions.scan;
      const params = { labelType: 'code39' as LabelType };
      const scanResult = await this.store.dispatch(
        SOFTLINE_FEATURE_SCANNER,
        scanAction,
        params
      );

      if (!scanResult || !scanResult?.data || !scanResult.labelType) {
        await this.handleInvalidScanResult();
        return;
      }

      const loadObjectAction = DocumentationStore.actions.loadObject;
      const labelType =
        typeof scanResult.labelType === 'string'
          ? scanResult.labelType
          : scanResult.labelType[0];
      const loadObjectParams = { order: scanResult?.data, labelType };
      const object = await this.store.dispatch(
        SOFTLINE_FEATURE_DOCUMENTATION,
        loadObjectAction,
        loadObjectParams
      );

      if (!object) {
        await this.handleObjectNotFound();
        return;
      }

      this.selectedObject$.next(object);
      this.form.patchValue({ object });
      this.cdRef.markForCheck();
    } catch (e) {
        this.isSaving$.next(false);
        handleRequestErrors(this.store, e);
    }
  }

  private async handleObjectNotFound(): Promise<void> {
    await this.showError();
  }

  private async handleInvalidScanResult(): Promise<void> {
    await this.showError();
  }

  private async showError(): Promise<void> {
    const message = {
      title: 'Auftrag nicht gefunden!',
      message: 'Es konnte kein Auftrag mit dem Barcode gefunden werden!',
    };

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      message
    );
    this.cdRef.markForCheck();
  }

  private async showSuccess(): Promise<void> {
    this.isSaving$.next(false);
    this.form.reset();

    const message = {
      title: 'Erfolgreich hochgeladen',
      message:
        'Die Dokumentation wurde erfolgreich gespeichert und hochgeladen!',
    };

    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      message
    );
    await this.router.navigate(['']);
    this.cdRef.detectChanges();
  }
}
