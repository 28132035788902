import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Store} from '@softline/core';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {SOFTLINE_FEATURE_SHOP_CART} from '../shop.shared';
import {CartStore} from '../store/cart.store';

@Injectable()
export class CartStoreGuard  {

  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private checkStore(): Observable<boolean> {
    return this.store.observe(SOFTLINE_FEATURE_SHOP_CART, CartStore.getters.dataLoaded).pipe(
      tap(async loaded => {
        if (loaded) { return; }
        await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.get);
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
