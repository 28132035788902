import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ShopItemAttribute} from '../../data/item-attribute';
import {ShopItem} from '../../data/item';
import {CommonModule} from '@angular/common';
import {UiCoreModule} from '@softline/ui-core';

@Component({
  selector: 'lib-list-item',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListItemComponent {

  @Input() item?: ShopItemAttribute | ShopItem;
  @Input() type: 'attribute' | 'item' = 'attribute';
  @Input() last = false;
  @Input() favoritesUpdating = false;
  @Input() isFavorite = false;
  @Input() cartAmount: number | null | undefined = null;

  @Output() selected = new EventEmitter<ShopItem | ShopItemAttribute>();
  @Output() addToCart = new EventEmitter<{ item: ShopItem, quantity: number | null | undefined }>();
  @Output() addToFavorites = new EventEmitter<ShopItem>();
  @Output() removeFromFavorites = new EventEmitter<ShopItem>();

  updatingFavorite = false;

  isAttribute(item: ShopItem | ShopItemAttribute | undefined): item is ShopItemAttribute {
    return (item as ShopItemAttribute)?.itemCount !== undefined;
  }

  isItem(item: ShopItem | ShopItemAttribute | undefined): item is ShopItem {
    return (item as ShopItem)?.number !== undefined;
  }

  onSelect(attribute: ShopItemAttribute | ShopItem | undefined): void {
    this.selected.emit(attribute);
  }

  favoritesTapped(item: ShopItem | undefined, action: 'remove' | 'add'): void {
    if (this.favoritesUpdating) { return; }
    action === 'add' ? this.addToFavorites.emit(item) : this.removeFromFavorites.emit(item);
  }

  addToCartTapped(item: ShopItem): void {
    this.addToCart.emit({ item, quantity: this.cartAmount });
  }
}
