 <div [softMeta]="meta()" [softValue]="value()" class="soft-grid w-full">
    <soft-meta-field name="kst" class="soft-overline col-span-3"></soft-meta-field>
    <div class="flex flex-row col-span-3 justify-end items-center">
      <div class="rounded-full px-2 py-1 flex flex-row justify-center items-center"
           [ngClass]="(value().kzverrzeit | in:['A', 'L', 'R']) ? 'bg-success text-success-contrast' : 'bg-surface-secondary'">
        <soft-meta-field name="kzverrzeit" class="text-xs font-semibold col-span-1 text-right"></soft-meta-field>
      </div>
    </div>
    <soft-meta-field name="object" class="text-lg font-bold col-span-5"></soft-meta-field>
    <soft-meta-field name="zeit" class="text-sm text-light col-span-1 text-right"></soft-meta-field>
    <soft-meta-field name="customer" class="text-light text-sm col-span-3"></soft-meta-field>
</div>

