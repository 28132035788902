import { inject, Pipe, PipeTransform } from '@angular/core';
import { MetaFieldNameDirective } from '../directives/object/meta-field-name.directive';
import { FormatPipe } from '@softline/ui-core';
import { MetaControlNameDirective } from '../directives/form/meta-control-name.directive';
import { meta } from 'jsonc-eslint-parser';

@Pipe({
  name: 'metaFormat',
  standalone: true,
})
export class MetaFormatPipe implements PipeTransform {
  meta = inject(MetaControlNameDirective, {optional: true}) ?? inject(MetaFieldNameDirective);

  formatPipe = inject(FormatPipe);

  transform(value: string | number | object | undefined | null | Date): string {
    if(!this.meta)
      throw new Error('[MetaFormatPipe] MetaFieldDirective is not provided.');
    return this.formatPipe.transform(value, this.meta.format());
  }
}
