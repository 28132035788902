<ng-container *ngIf="objekt$ | async as objekt">
  <h2 class="text-xl font-semibold mt-3 mb-2">
    {{ '#OBJEKT.RESULT.OBJEKT.OBJEKT' | translate }}
  </h2>
  <section class="soft-container">
    <div
      class="soft-card soft-card-rounded w-full divide-y divide-gray-200"
      style="padding: 0"
    >
      <div class="soft-container space-between p-4">
        <div class="soft-container-column-start text-sm font-semibold">
          {{ '#OBJEKT.RESULT.OBJEKT.ID' | translate }}
        </div>
        <div class="soft-container-column-end text-sm">
          {{ objekt?.id || '-' }}
        </div>
      </div>
      <div class="soft-container space-between p-4">
        <div class="soft-container-column-start text-sm font-semibold">
          {{ '#OBJEKT.RESULT.OBJEKT.NUMMER' | translate }}
        </div>
        <div class="soft-container-column-end text-sm">
          {{ objekt?.number || '-' }}
        </div>
      </div>
      <div class="soft-container space-between p-4">
        <div class="soft-container-column-start text-sm font-semibold">
          {{ '#OBJEKT.RESULT.OBJEKT.NAME' | translate }}
        </div>
        <div class="soft-container-column-end text-sm">
          {{ objekt?.name || '-' }}
        </div>
      </div>
    </div>
  </section>

  <ng-container *ngIf="objekt && objekt?.geodaten">
    <div class="soft-container space-between mb-2 mt-6">
      <div class="title">
        {{ '#OBJEKT.RESULT.GEODATEN.GEODATEN' | translate }}
      </div>
      <a
        target="_blank"
        class="soft-link"
        [attr.href]="getGoogleLink(objekt)"
        >{{ '#OBJEKT.RESULT.GEODATEN.GOOGLE_MAPS' | translate }}</a
      >
    </div>
    <section class="soft-container">
      <div class="soft-card soft-card-rounded w-full border-bottom">
        <div class="soft-container space-between pt-4 pb-3">
          <div class="soft-container-column-start">
            <strong>{{
              '#OBJEKT.RESULT.GEODATEN.GPSBREITE' | translate
            }}</strong>
          </div>
          <div class="soft-contianer--column-end">
            {{ objekt?.geodaten?.gpsbreite || '-' }}
          </div>
        </div>
        <div class="soft-container space-between pt-4 pb-3">
          <div class="soft-container-column-start">
            <strong>{{
              '#OBJEKT.RESULT.GEODATEN.GPSLAENGE' | translate
            }}</strong>
          </div>
          <div class="soft-contianer--column-end">
            {{ objekt?.geodaten?.gpslaenge || '-' }}
          </div>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="objekt?.archiveKey">
    <div class="flex justify-between mb-2 mt-8 items-center">
      <h2 class="text-xl font-semibold">
        {{ '#OBJEKT.RESULT.DOCUMENTS' | translate }}
      </h2>
    </div>
    <section class="w-full">
      <soft-archive-list
        [archiveKey]="objekt.archiveKey"
        [showFilter]="true"
        style="width: 100%"
      ></soft-archive-list>
    </section>
  </ng-container>
</ng-container>
