import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CartItem} from '../../../data/cart-item';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-cart-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartItemComponent {
  @Input() item?: CartItem;
  @Input() last = false;

  @Output() delete = new EventEmitter<CartItem>();
  @Output() edit = new EventEmitter<CartItem>();
}
