<div class="max-height-70 min-height-50 pos-rel">
  <div class="pt-3 pr-3 pl-3 overflow-auto">
    <h4 class="text-lg font-semibold">{{ '#SHOP.DIALOGS.ADDRESS.TITLE' | translate }}</h4>

    <div class="c column no-wra height-100 pb-buttons" *ngIf="addresses$ | async as addresses">
      <div
        class="c row space-between no-wrap center-v pt-3 pb-3"
        (click)="select(address)"
        *ngFor="let address of addresses; let last = last; trackBy: $any(trackByFn)"
        [class.border-bottom]="!last"
        [class.bg-active]="selectedAddress && (selectedAddress?.id === address.id)">
        <div>
          <div class="mb-1">{{ address?.firstName }} {{ address?.lastName }}</div>
          <div class="text-sm color-light">{{ address?.zipCode }} {{ address?.city }}</div>
        </div>
        <i class="fa-regular fa-check color-primary ml-2 pr-2 mr-2" *ngIf="selectedAddress && (selectedAddress?.id === address.id)"></i>
      </div>
    </div>
  </div>

  <div class="c row no-wrap center-v pos-abs">
    <button class="soft-button secondary large-button" (click)="close(null)">{{ '#SHOP.DIALOGS.ADDRESS.CANCEL' | translate }}</button>
    <button class="soft-button primary large-button" (click)="setAddress()" [disabled]="!selectedAddress">{{ '#SHOP.DIALOGS.ADDRESS.SET' | translate }}</button>
  </div>
</div>

