<h2 class="text-xl font-bold mt-3 mb-1">{{ '#SHOP.ORDER_HISTORY.TITLE' | translate }}</h2>

<div class="c column no-wrap pb-4">
  <ng-container *ngIf="loading$ | async; else content">
    <div class="c row center pt-4 pb-4 no-wrap min-height-50">
      <soft-loading-spinner class="spin"></soft-loading-spinner>
    </div>
  </ng-container>

  <ng-template #content>
    <ng-container *ngIf="orders$ | async as orders">
      <ng-container *ngIf="(orders?.length ?? 0) < 1; else items">
      <div class="c row no-wrap center text-sm color-light min-height-50 pt-4 pb-4">
        {{ '#SHOP.ORDER_HISTORY.NO_ITEMS_FOUND' | translate }}
      </div>
      </ng-container>
      <ng-template #items>
        <lib-order-history-item
          *ngFor="let order of orders; trackBy: trackByFn"
          [order]="order"
          (click)="openDetails(order)"
        ></lib-order-history-item>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
