import {Inject, Injectable} from '@angular/core';
import {CartItem} from '../data/cart-item';
import {base64Encode, ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';
import {CART_ITEMS_RESOURCE_PATH, CART_RESOURCE_PATH} from '../shop.api';
import {Observable} from 'rxjs';
import {Cart} from '../data/cart';

@Injectable()
export class CartResourceService {

  constructor( @Inject(SOFTLINE_SERVICE_HTTP) private service: ConnectionHttpService ) { }

  loadCart(): Observable<Cart> {
    return this.service.get<Cart>({path: CART_RESOURCE_PATH});
  }

  addItem(entity: Partial<CartItem>): Observable<Cart> {
    const flattenedItem = this.removeKeys(entity.item, ['isFavorite', 'isUpdatingFavorite']);
    const encodedEntity = base64Encode(JSON.stringify({ ...entity, item: flattenedItem}));
    return this.service.create<string, Cart>({path: CART_ITEMS_RESOURCE_PATH}, encodedEntity);
  }

  removeItem(id: number): Observable<Cart> {
    return this.service.delete<Cart>({path: CART_ITEMS_RESOURCE_PATH + '/' + id});
  }

  updateItem(item: CartItem): Observable<Cart> {
    const flattenedItem = this.removeKeys(item.item, ['isFavorite', 'isUpdatingFavorite']);
    console.log('item for update: ', item);
    const encodedItem = base64Encode(JSON.stringify({ ...flattenedItem }));
    return this.service.update<unknown, Cart>({path: CART_ITEMS_RESOURCE_PATH + '/' + item.id}, encodedItem);
  }

  setAddress(encodedCart: string): Observable<Cart> {
    return this.service.update<unknown, Cart>({path: CART_RESOURCE_PATH}, encodedCart);
  }

  confirmOrder(cart: Cart): Observable<Cart> {
    const encodedCart = base64Encode(JSON.stringify(cart));
    return this.service.create<unknown, Cart>({path: CART_RESOURCE_PATH + '/confirm'}, encodedCart);
  }

  private removeKeys(item: any, keys: string[]): CartItem {
    const itemCopy = item;
    keys.forEach(key => delete itemCopy[key]);
    return itemCopy;
  }
}
