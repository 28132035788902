import { Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  ConnectionResourceLocation,
  Dictionary,
} from '@softline/core';
import { Employee } from '../models/employee.model';
import { COMPANY_DATA_RESOURCE_PATH } from '../arbeitsbericht.api';
import { Observable } from 'rxjs';
import { Stammdaten } from '../models/stammdaten';
import { map } from 'rxjs/operators';

@Injectable()
export class CoreDataService {
  constructor(private service: ConnectionHttpService) {}

  getMany(
    pathParams?: Dictionary<unknown>,
    queryParams?: Dictionary<unknown>
  ): Observable<Employee[]> {
    const location: ConnectionResourceLocation = {
      path: COMPANY_DATA_RESOURCE_PATH,
    };
    return this.service
      .get<Stammdaten>(location)
      .pipe(map((response) => response?.mitarbeiterInAbteilungModule ?? []));
  }

  getCoreData(): Observable<Stammdaten> {
    const location: ConnectionResourceLocation = {
      path: COMPANY_DATA_RESOURCE_PATH,
    };
    return this.service.get<Stammdaten>(location);
  }
}
