export const environment = {
  production: true,
  connection: {
    name: 'Reisinger Bauplanung',
    host: 'https://bauplanung.reisinger-bauen.at',
    port: 443,
    basePath: '/restapi/api'
  },
  androidUpdateRepository: 'https://apps.softline.at/customers/reisinger/bauplanung'
};
