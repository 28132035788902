import { Injectable } from '@angular/core';
import {
  ConnectionHttpService,
  ConnectionResourceLocation,
} from '@softline/core';
import { PARTY_FAVORITES_RESOURCE_PATH } from '../arbeitsbericht.api';
import { Observable } from 'rxjs';
import { FavoriteEmployee } from '../models/favorite-employee';

@Injectable()
export class PartyFavoritesService {
  constructor(private service: ConnectionHttpService) {}

  markAsFavorite(person: FavoriteEmployee): Observable<FavoriteEmployee> {
    const location: ConnectionResourceLocation = {
      path: PARTY_FAVORITES_RESOURCE_PATH,
      pathParams: undefined,
    };
    return this.service.create<any, FavoriteEmployee>(location, person);
  }
}
