import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@softline/core';
import {
  ObjectQueryStore,
  SOFTLINE_FEATURE_OBJECT_QUERY,
} from '../../objektabfrage.shared';
import { Objekt } from '../../data/objekt';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import {
  ApplicationModule,
  ArchiveStore,
  BackNavigable,
  BackNavigationService, SOFTLINE_FEATURE_ARCHIVE,
} from '@softline/application';

@Component({
  selector: 'lib-object-result',
  standalone: true,
  templateUrl: './object-result.component.html',
  styleUrls: ['./object-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule, ApplicationModule],
})
export class ObjectResultComponent implements OnInit, OnDestroy, BackNavigable {
  private subscription?: Subscription;
  public GOOGLE_LINK = 'https://www.google.com/maps/search/?api=1&query=';
  readonly objekt$: Observable<Readonly<Objekt | null>> = this.store.observe(
    SOFTLINE_FEATURE_OBJECT_QUERY,
    ObjectQueryStore.getters.entity
  );

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private backNavigationService: BackNavigationService
  ) {}

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.subscription = this.activatedRoute.paramMap
      .pipe(map((o) => o.get('id')))
      .subscribe(async (id) => {
        if(!id)
          return;
        const objekt = await this.store.dispatch(
          SOFTLINE_FEATURE_OBJECT_QUERY,
          ObjectQueryStore.actions.load,
          { id }
        );

        await this.store.dispatch(
          SOFTLINE_FEATURE_OBJECT_QUERY,
          ObjectQueryStore.actions.loadArchiveKey,
          objekt
        );
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.backNavigationService.set(undefined);
    this.store.commit(
      SOFTLINE_FEATURE_OBJECT_QUERY,
      ObjectQueryStore.mutations.set,
      null
    );

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = undefined;
    await this.store.dispatch(
      SOFTLINE_FEATURE_OBJECT_QUERY,
      ObjectQueryStore.actions.cancelAll
    );

    // We have to delete the archive in order to always get the latest sorting order
    // if we do not clear the archive store, the next time we read the same object any changes will be displayed
    // at the bottom of the list instead of the correct sorting order from the api
    await this.store.commit(
      SOFTLINE_FEATURE_ARCHIVE,
      ArchiveStore.mutations.clear
    );
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/objektabfrage']);
  }

  getGoogleLink(objekt: any): string | undefined {
    let link = this.GOOGLE_LINK;
    if (objekt.geodaten !== null) {
      link += objekt?.geodaten?.gpsbreite + ',' + objekt?.geodaten?.gpslaenge;
      return encodeURI(link);
    }
    return undefined;
  }
}
