<div class="c row no-wrap center-v space-between pt-3 pb-3 rounded soft-card mt-3" *ngIf="order">
  <div class="c column no-wrap center-v truncate">
    <span class="soft-overline mb-1">{{ order.number }} | {{ order.items.length}} Artikel</span>
    <strong class="truncate mb-1">{{ order.date | date:'dd.MM.yyyy'}}</strong>
    <span *ngIf="order.items" class="text-sm truncate color-light">{{ order.items[0]?.objektUrsache?.objekt }} - {{ order.items[0]?.objektUrsache?.bezeichnung }}</span>
  </div>
  <div class="trailing-content">
    <span class="soft-overline color-tertiary" style="margin-top: 1px">{{ order?.status }}</span>
    <i class="fa-regular fa-chevron-right text-sm color-light ml-3" style="opacity: 0.5"></i>
  </div>
</div>
