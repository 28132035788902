import { Pipe, PipeTransform } from '@angular/core';
import { ShopItem } from '../data/item';

@Pipe({
  name: 'toShopItems',
  standalone: true,
  pure: true
})
export class ToShopItemsPipe implements PipeTransform {
  transform(value: { id: number | string; item: ShopItem }[], ...args: any): any {
    return value.map(o => o.item);
  }
}
