import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {importProvidersFrom} from '@angular/core';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineEntityService,
  SoftlineObjectService,
  StoreModule
} from '@softline/core';
import {FavoritesStoreGuard} from './guards/favorites.guard';
import {CartStoreGuard} from './guards/cart.guard';
import {
  AddressStore,
  AttributeStore,
  RecentObjectsStore,
  SOFTLINE_FEATURE_SHOP_ADDRESS,
  SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
  SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION,
  SOFTLINE_FEATURE_SHOP_CART,
  SOFTLINE_FEATURE_SHOP_FAVORITES,
  SOFTLINE_FEATURE_SHOP_ITEMS,
  SOFTLINE_FEATURE_SHOP_ORDER_HISTORY,
  SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS,
  SOFTLINE_FEATURE_SHOP_STAMMDATEN,
  SOFTLINE_PERMISSION_SHOP
} from './shop.shared';
import {OrdersStoreGuard} from './guards/orders.guard';
import {
  FAVORITES_RESOURCE_PATH,
  GET_STAMMDATEN,
  ITEM_ATTRIBUTES,
  ITEM_ENTITIES,
  ORDER_HISTORY_RESOURCE_PATH,
  RECENT_OBJECTS_RESOURCE_PATH
} from './shop.api';
import {StammdatenStore} from './store/stammdaten.store';
import {OrderHistoryStore} from './store/order-history.store';
import {FavoritesStore} from './store/favorites.store';
import {ItemsStore} from './store/items.store';
import {ItemResourceService} from './services/item-resource.service';
import {CartStore} from './store/cart.store';
import {CartResourceService} from './services/cart-resource.service';
import {ScanOrderService} from './services/order-scan.service';
import {AttributeNavigationStore} from './store/attribute-navigation.store';
import {AttributeNavigationService} from './services/attribute-navigation.service';

export const bestellungRoutes: Routes = [
  {
    path: 'shop',
    loadComponent: () => import('./pages/shop.component').then(o => o.ShopComponent),
    children: [

      { path: 'items', loadComponent: () => import('./pages/items/items.component').then(o => o.ItemsComponent) },
      { path: 'search', loadComponent: () => import('./pages/search/item-search.component').then(o => o.ItemSearchComponent) },
      { path: 'cart', loadComponent: () => import('./pages/cart/cart.component').then(o => o.CartComponent) },
      { path: 'favorites', loadComponent: () => import('./pages/favorites/favorites.component').then(o => o.FavoritesComponent) },
      { path: 'orders', loadComponent: () => import('./pages/order-history/order-history.component').then(o => o.OrderHistoryComponent) },
      { path: 'orders/:id', loadComponent: () => import('./pages/order-history/order-history.component').then(o => o.OrderHistoryComponent), canActivate: [OrdersStoreGuard] },
      { path: '' , loadComponent: () => import('./pages/attribute-list/attribute-list.component').then(o => o.AttributeListComponent), },
      { path: '**', redirectTo: '/shop' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard, FavoritesStoreGuard, CartStoreGuard],
    data: {permission: SOFTLINE_PERMISSION_SHOP},
    providers: [
      ItemResourceService,
      CartResourceService,
      FavoritesStoreGuard,
      CartStoreGuard,
      OrdersStoreGuard,
      ScanOrderService,
      AttributeNavigationService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_ADDRESS,
          feature: AddressStore.feature,
          providers: []
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_ATTRIBUTE,
          feature: AttributeStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, ITEM_ATTRIBUTES),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS,
          feature: RecentObjectsStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, RECENT_OBJECTS_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_STAMMDATEN,
          feature: StammdatenStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, GET_STAMMDATEN),
              deps: [SOFTLINE_SERVICE_HTTP]
            },
            {
              provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineObjectService(service, GET_STAMMDATEN),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_ORDER_HISTORY,
          feature: OrderHistoryStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, ORDER_HISTORY_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_FAVORITES,
          feature: FavoritesStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, FAVORITES_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_ITEMS,
          feature: ItemsStore.feature,
          providers: [
            {
              provide: ItemResourceService,
              useFactory: (service: ConnectionHttpService) => new ItemResourceService(service),
              deps: [SOFTLINE_SERVICE_HTTP]
            },
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, ITEM_ENTITIES),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }),
       /* StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_CART,
          feature: CartStore.feature,
          providers: [
            {
              provide: CartResourceService,
              useFactory: (service: ConnectionHttpService) => new CartResourceService(service),
              deps: [SOFTLINE_SERVICE_HTTP]
            },
            {
              provide: ScanOrderService,
              useFactory: (service: ConnectionHttpService) => new ScanOrderService(service),
              deps: [SOFTLINE_SERVICE_HTTP]
            }
          ]
        }), */
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_SHOP_ATTRIBUTE_NAVIGATION,
          feature: AttributeNavigationStore.feature,
          providers: []
        })
      )
    ]
  },
];
