import { RemoteCollectionStore } from '@softline/core';
import { Objekt } from './models/objekt.model';
import { InjectionToken, Type } from '@angular/core';
import { TimeReportItemComponent } from './components/time-report-item/time-report-item.component';

export const SOFTLINE_FEATURE_TIME_REPORT_RECENT_OBJECT =
  'timeReportRecentObject';
export const SOFTLINE_FEATURE_TIME_REPORT_CORE_DATA = 'timeReportCoreData';
export const SOFTLINE_FEATURE_TIME_REPORT_PARTY_FAVORITES =
  'timeReportPartyFavorites';
export const SOFTLINE_FEATURE_TIME_REPORT_TIME_REPORT = 'timeReportTimeReport';

export const RecentObjectsStore = RemoteCollectionStore.create<Objekt>();

export const SOFTLINE_COMPONENT_TIME_REPORT_ITEM = new InjectionToken<Type<TimeReportItemComponent>>('SOFTLINE_COMPONENT_TIME_REPORT_ITEM', {
  providedIn: 'root',
  factory: () => TimeReportItemComponent
});
