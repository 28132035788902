import {CartItem} from '../data/cart-item';
import {PriceInformation} from '../data/price';
import {Address} from '../data/address';
import {Objekt} from '../data/objekt';
import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  KeyStore,
  mutate,
  ObjectStore,
  on,
  ParamsStore,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreBuilder,
  StoreFeature,
  SyncedRemoteObjectStore,
} from '@softline/core';
import {Cart} from '../data/cart';
import {CartResourceService} from '../services/cart-resource.service';
import {LabelType} from '@softline/application';
import {ScanOrderService} from '../services/order-scan.service';
import {lastValueFrom} from 'rxjs';

export interface CartState {
  id?: number | undefined;
  items?: CartItem[];
  total?: PriceInformation | null;
  deliveryAddress?: Address | undefined;
  object?: Objekt | null;
  processing?: boolean;
  orderLocation?: any;
}

export type State = ObjectStore.State<CartState>;

class CartStoreExtension {
  static actions = {
    get: createAction<State, undefined, Cart>('getCart'),
    add: createAction<State, { entity: Partial<CartItem> }, undefined>('addToCart'),
    remove: createAction<State, { id: number }, undefined>('removeFromCart'),
    update: createAction<State, { id: number, item: CartItem }, undefined>('updateCart'),
    order: createAction<State, Cart & any, undefined>('order'),
    setAddress: createAction<State, { encodedCard: string }, undefined>('setAddress'),
    getObjectForOrder: createAction<State, { result: string, labelType: LabelType | string }, undefined>('getObjectForOrder')
  };

  static mutations = {
    setOrderLocation: createMutation<State, { location: any }>('setOrderLocation'),
    selectObject: createMutation<State, { object: Objekt }>('selectObject')
  };

  static getters = {
    get: createGetter<State, any, Cart>('get'),
    selectedObject: createGetter<State, any, Objekt | null>('selectObject'),
    cartState: createGetter<State, any, CartState>('cartState'),
    processing: createGetter<State, any, boolean>('processing'),
    orderLocation: createGetter<State, any, any>('orderLocation'),
    cartItems: createGetter<State, CartItem[]>('cartItems'),
    dataLoaded: createGetter<State, boolean>('cartHasDataLoaded')
  };

  static feature: Partial<StoreFeature<State>> = {
    actions: [
      on(CartStoreExtension.actions.get, async ({featureName, commit, state, injector}) => {
        const service = injector.get(CartResourceService);

        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        //commit(featureName, KeyStore.mutations.add, token);
        //commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.loadCart();
        const result = await lastValueFrom(observable);

        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.add, async  ({featureName, commit, params, state, injector}) => {
        const service = injector.get(CartResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        // commit(featureName, KeyStore.mutations.add, token);
        // commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.addItem(params.entity);
        const result = await lastValueFrom(
          observable
        );

        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.remove, async ({featureName, commit, params, state, injector}) => {
        const service = injector.get(CartResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        // commit(featureName, KeyStore.mutations.add, token);
        // commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.removeItem(params.id);
        const result = await lastValueFrom(
          observable
        );
        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.update, async ({featureName, commit, params, state, injector}) => {
        const service = injector.get(CartResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        // commit(featureName, KeyStore.mutations.add, token);
        // commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.updateItem(params.item);
        const result = await lastValueFrom(
         observable
        );
        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.order, async ({featureName, commit, params, state, injector}) => {
        const service = injector.get(CartResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
       // commit(featureName, KeyStore.mutations.add, token);
        //commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.confirmOrder(params);
        const result = await lastValueFrom(
          observable
        );
        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.setAddress, async ({featureName, commit, params, state, injector}) => {
        const service = injector.get(CartResourceService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
       // commit(featureName, KeyStore.mutations.add, token);
        //commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.setAddress(params.encodedCard);
        const result = await lastValueFrom(
          observable
        );
        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      }),
      on(CartStoreExtension.actions.getObjectForOrder, async ({featureName, commit, params, state, injector}) => {
        const service = injector.get(ScanOrderService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        // commit(featureName, KeyStore.mutations.add, token);
        // commit(featureName, ParamsStore.mutations.add, {key: token, params});

        const observable = service.getObjectForOrder(params.result, params.labelType);
        const result = await lastValueFrom(
          observable
        );
        commit(featureName, SyncedRemoteObjectStore.mutations.set, { ...state.data, ...result });
        return result;
      })
    ],
    mutations: [
      mutate(CartStoreExtension.mutations.setOrderLocation, ({params, state}) =>({
        data: { ...state.data, orderLocation: params.location ?? undefined }
      })),
      mutate(CartStoreExtension.mutations.selectObject, ({params, state}) => ({
        data: { ...state.data, object: params.object },
      }))
    ],
    getters: [
      select(CartStoreExtension.getters.selectedObject, ({state}) => (state as State)?.data?.object),
      select(CartStoreExtension.getters.dataLoaded, ({state}) => !!(state as State)?.data && Object.keys(state?.data ?? {})?.length > 0),
      select(CartStoreExtension.getters.cartState, ({state}) => (state as State)?.data as CartState),
      select(CartStoreExtension.getters.processing, ({state}) => (state as State)?.data?.processing),
      select(CartStoreExtension.getters.orderLocation, ({state}) => (state as State)?.data?.orderLocation),
      select(CartStoreExtension.getters.cartItems, ({state}) => (state as State)?.data?.items ?? [])
    ]
  };
}

export const CartStore = new StoreBuilder(SyncedRemoteObjectStore.create<Cart>())
  .add(ParamsStore)
  .add(KeyStore)
  .add(CartStoreExtension)
  .value;
