<div class="pb-2" *ngIf="items$ | async as shopItems">
  <ng-container *ngIf="(loading$ | async) || (loading !== undefined && loading === true); else content">
    <div class="c row center no-wrap pt-4 pb-4 mt-1 min-height-50">
      <soft-loading-spinner class="spin"></soft-loading-spinner>
    </div>
  </ng-container>

  <ng-template #content>
    <lib-list-item
      *ngFor="let item of shopItems; let last = last; trackBy: $any(trackByFn)"
      [item]="item.item"
      [last]="last"
      [type]="'item'"
      [isFavorite]="item?.isFavorite || false"
      [favoritesUpdating]="item?.isUpdatingFavorite || false"
      [cartAmount]="item?.cartAmount"
      (addToCart)="onAddToCart($event)"
      (removeFromFavorites)="onRemoveFromFavorites($event)"
      (addToFavorites)="onAddToFavorites($event)">
    </lib-list-item>

    <ng-container *ngIf="displayType === 'search'">
      <ng-container *ngIf="shopItems?.length === 0 && isInputEmpty">
        <div class="c row no-wrap mt-2 pt-4 pb-4 center min-height-50">
        <span class="text-s color-light text-center">
          {{ '#SHOP.ITEM_LIST.EMPTY_SEARCH' | translate }}
        </span>
        </div>
      </ng-container>

      <ng-container *ngIf="shopItems?.length === 0 && !isInputEmpty">
        <div class="c row no-wrap mt-2 pt-4 pb-4 center min-height-50">
        <span class="text-sm color-light text-center">
          {{ '#SHOP.ITEM_LIST.NO_ITEMS' | translate }}
        </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
