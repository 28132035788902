<div class="c column" style="min-height: 300px; overflow-y: hidden">
  <div
    class="c row space-between no-wrap center-v pt-2 pr-2 pl-2 pb-2 border-box"
  >
    <h4 class="text-lg font-bold">
      {{ '#TIME_REPORT.DIALOGS.PARTY.TITLE' | translate }}
    </h4>
    <span
      class="soft-text-s color-light selection-count"
      [ngStyle]="{
        visibility: (selectedPersons?.length ?? 0) > 0 ? 'visible' : 'hidden'
      }"
    >
      {{ selectedPersons?.length }}
      {{ '#TIME_REPORT.DIALOGS.PARTY.SELECTED_COUNT_POSTFIX' | translate }}
    </span>
  </div>

  <div class="c row mr-2 mt-2 pr-2 border-box">
    <soft-input
      placeholder="Suche nach Person"
      class="border-box mr-2 ml-2"
      #search
      (valueChange)="searchInput$.next(search?.value ?? '')"
    ></soft-input>
  </div>

  <ng-container *ngIf="loading$ | async">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
  </ng-container>

  <ng-container
    *ngIf="filteredPersons$ | async as partyPersons; else loadingTemplate"
  >
    <div
      class="c column"
      *ngIf="(partyPersons?.length ?? 0) > 0; else noPersonsTemplate"
      style="position: relative; flex-grow: 1; flex-shrink: 0"
    >
      <ul
        class="button-spacing soft-list mt-2 border-box soft-scroll soft-scrollno-horizontal soft-scroll-vertical pt-2 pr-2 pl-2 pb-1 border-box"
        style="max-height: 60vh"
      >
        <li
          class="soft-list-item soft-text custom-item"
          [class.selected-employee]="
            person | isSelected: selectedPersons:comparerFn
          "
          *ngFor="let person of partyPersons; let i = index"
        >
          <div class="c row center no-wrap space-between">
            <div class="c column no-wrap" (click)="selectOrUnselect(person)">
              <strong>{{ person?.vorname }} {{ person?.zuname }}</strong>
              <p class="soft-text-s color-light mb-0 mt-0">
                {{ person?.persnr }}
              </p>
            </div>

            <span
              class="icon"
              style="display: inline-block"
              (click)="favoriteChange(person)"
            >
              <i
                class="fa-star"
                [ngClass]="person?.favorite ? 'fa-solid' : 'fa-regular'"
              ></i>
            </span>
          </div>
        </li>
      </ul>

      <div class="c row center-v" style="position: absolute; bottom: 0">
        <button
          class="soft-button primary w-full text-center large-button"
          (click)="close(selectedPersons)"
        >
          {{ '#TIME_REPORT.DIALOGS.PARTY.SAVE' | translate }}
        </button>
      </div>
    </div>

    <ng-template #noPersonsTemplate>
      <div style="width: calc(100% - 1rem)">
        <div
          class="soft-text-s color-light mt-4 pt-2 mb-2 pb-2 text-center c row center"
        >
          {{ '#TIME_REPORT.DIALOGS.PARTY.NO_PERSONS_FOUND' | translate }}
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="c center spinner-container">
      <soft-loading-spinner class="spin"></soft-loading-spinner>
    </div>
  </ng-template>
</div>
