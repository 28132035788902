import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Address} from '../../data/address';
import {base64Encode, Store} from '@softline/core';
import {Modal, UiCoreModule} from '@softline/ui-core';
import {Cart} from '../../data/cart';
import {Objekt} from '../../data/objekt';
import {
  AddressStore,
  RecentObjectsStore,
  SOFTLINE_FEATURE_SHOP_ADDRESS,
  SOFTLINE_FEATURE_SHOP_CART,
  SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS
} from '../../shop.shared';
import {CartStore} from '../../store/cart.store';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'lib-address-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDialogComponent implements Modal<any>, OnInit {

  selectedAddress: Address | undefined | null = undefined;
  cart: Cart | undefined = undefined;

  addresses$: Observable<Objekt[]> = this.store.observe(SOFTLINE_FEATURE_SHOP_RECENT_OBJECTS, RecentObjectsStore.getters.all);

  close: any;

  readonly trackByFn = (_: number, item: Address) => item.id;

  constructor(private store: Store) { }

  registerCloseHandler(handler: (result: any) => void): void {
        this.close = handler;
    }

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_SHOP_ADDRESS, AddressStore.actions.loadMany, { clear: true });
  }

  select(address: Address): void {
    this.selectedAddress = address;
  }

  async setAddress(): Promise<void> {
    if (!this.cart || !this.selectedAddress) { return; }
    const updatedCart = { ...this.cart, deliveryAddress: this.selectedAddress };
    const encodedCard = base64Encode(JSON.stringify(updatedCart));
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_SHOP_CART, CartStore.actions.setAddress, {encodedCard});
      this.close(null);
    } catch {
      console.log('Error - Could not set address');
    }
  }
}
