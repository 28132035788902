import { importProvidersFrom } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthenticationGuard, AuthorizationGuard } from '@softline/auth';
import {
  ConnectionHttpService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule,
} from '@softline/core';
import {
  ObjectQueryRecentObjectsStore,
  ObjectQueryStore,
  SOFTLINE_FEATURE_OBJECT_QUERY, SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
  SOFTLINE_PERMISSION_OBJECT_QUERY,
} from './objektabfrage.shared';
import { OBJECT_RESOURCE_PATH } from './objektabfrage.api';
import { ArchiveKeyService } from './services/archive-key.service';
import {ScanOrderService} from './services/scan-order.service';

export const objektabfrageRoutes: Routes = [
  {
    path: 'objektabfrage',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/object-query.component').then(
            (o) => o.ObjectQueryComponent
          ),
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./components/object-input/object-input.component').then(
                (o) => o.ObjectInputComponent
              ),
            pathMatch: 'full',
          },
          {
            path: ':id',
            loadComponent: () =>
              import('./components/object-result/object-result.component').then(
                (o) => o.ObjectResultComponent
              ),
          },
          { path: '**', redirectTo: '' },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: SOFTLINE_PERMISSION_OBJECT_QUERY },
    providers: [
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_OBJECT_QUERY,
          feature: ObjectQueryStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) =>
                new SoftlineEntityService(service, OBJECT_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: ArchiveKeyService,
              useFactory: (service: ConnectionHttpService) =>
                new ArchiveKeyService(service),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ],
        }),
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_OBJECT_QUERY_RECENT_OBJECTS,
          feature: ObjectQueryRecentObjectsStore.feature,
          providers: [
            {
              provide: SOFTLINE_STORE_ENTITY_SERVICE,
              useFactory: (service: ConnectionHttpService) => new SoftlineEntityService(service, OBJECT_RESOURCE_PATH),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
            {
              provide: ScanOrderService,
              useFactory: (service: ConnectionHttpService) => new ScanOrderService(service),
              deps: [SOFTLINE_SERVICE_HTTP],
            },
          ]
        })
      )
    ],
  },
];
