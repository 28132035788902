import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { SOFTLINE_STORE_FEATURES, Store } from '@softline/core';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from '@softline/application';
import { SOFTLINE_PERMISSION_TIME_REPORT } from './arbeitsbericht.permissions';
import { SOFTLINE_CONFIG_ARBEITSBERICHT } from './arbeitsbericht.config';
import { SOFTLINE_CONFIG_DEFINITION } from '@softline/dynamic';
import {
  SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
  SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY
} from './arbeitsbericht.api';
import { default as queryDefaultDefinition } from './definitions/query.json';
import { default as editDefaultDefinition } from './definitions/edit.json';
import { default as inputDefaultDefinition } from './definitions/input.json';
import { default as listDefaultDefinition } from './definitions/list.json';
import { TimeReportStore2 } from './store/time-report.store';

export const arbeitsberichtProviders = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'arbeitsbericht',
          language: 'de',
          translations: de,
        }
      );
      store.commit(
        SOFTLINE_FEATURE_APPLICATIONS,
        ApplicationStore.mutations.add,
        {
          name: '#TIME_REPORT.TITLE',
          icon: 'fa-regular fa-business-time',
          route: '/arbeitsbericht',
          permission: SOFTLINE_PERMISSION_TIME_REPORT,
        }
      );
    },
    multi: true,
  },
  { provide: SOFTLINE_CONFIG_ARBEITSBERICHT, useValue: {} },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_ARBEITSBERICHT_LIST,
      definition: listDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_ARBEITSBERICHT_INPUT,
      definition: inputDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_ARBEITSBERICHT_EDIT,
      definition: editDefaultDefinition
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_DEFINITION,
    useValue: {
      name: SOFTLINE_DEFINITION_ARBEITSBERICHT_QUERY,
      definition: queryDefaultDefinition
    },
    multi: true
  },
  { provide: SOFTLINE_STORE_FEATURES, useExisting: TimeReportStore2, multi: true},
];
