<div class="c column pt-2 pr-2 pl-2 pb-2 border-box">
  <h4 class="text-lg font-bold mt-2">
    {{ '#TIME_REPORT.DIALOGS.RECENT_OBJECTS.TITLE' | translate }}
  </h4>
  <span class="soft-text-s color-light">{{
    '#TIME_REPORT.DIALOGS.RECENT_OBJECTS.HINT' | translate
  }}</span>

  <div class="soft-scroll soft-scroll-vertical w-full mt-2 border-box">
    <ng-container *ngIf="loading$ | async; else contentTemplate">
      <soft-loading-spinner class="spin"></soft-loading-spinner>
    </ng-container>

    <ng-template #contentTemplate>
      <ng-container *ngIf="recentObjects$ | async as recentObjects">
        <ul class="soft-list mt-2">
          <li
            class="soft-list-item custom-item"
            (click)="close(object)"
            *ngFor="let object of recentObjects"
          >
            <strong>{{ object.name }}</strong>
            <div class="soft-text-s color-light mt-1">{{ object.number }}</div>
          </li>
        </ul>
      </ng-container>
    </ng-template>
  </div>
</div>
